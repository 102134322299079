<template>
  <div id="app">
    <nav class="navbar navbar-default navbar-fixed-top">
      <div class="container-fluid">
       <!-- Brand and toggle get grouped for better mobile display -->
        <div class="navbar-header">
          <button type="button" class="navbar-toggle collapsed" data-toggle="collapse" data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
            <span class="sr-only">Toggle navigation</span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
            <span class="icon-bar"></span>
          </button>
          <strong class="navbar-brand">株式会社SBS</strong>
        </div>
         <!-- Collect the nav links, forms, and other content for toggling -->
        <div class="collapse navbar-collapse" id="bs-example-navbar-collapse-1">
          <ul class="nav navbar-nav navbar-right">
            <li class="nav-active"><router-link to="/">ホーム</router-link></li>
            <li><router-link to="/corporate">会社情報</router-link></li>
            <li><router-link to="/business">事業内容</router-link></li>
            <li><router-link to="/recruit">採用情報</router-link></li>
            <li><router-link to="/contact">お問い合わせ</router-link></li>
          </ul>
        </div>
        <!-- /.navbar-collapse -->
      </div>
    </nav>
    <div style="margin-top: 110px;">
      <router-view/>
    </div>
  
  <!--footer-->
    <div class="footer-container container-fluid">
        <footer>
          <p class="pagetop" @click="backTop()" v-show="tabshow">
            <a> <img src="./assets/image/bt_pagetop.svg" alt="ページトップへ戻る"/></a>
          </p>
          <p class="copyright">
            <small> Copyright ©2018  株式会社SBS CO., LTD. All Rights Reserved.</small>
           </p>
        </footer>
    </div>
  </div>
</template>

<script>

export default({
  data(){
    return {
      scrollTopNum: "",
      tabshow: false,
    }
  },
  mounted() {
      window.addEventListener("scroll", this.handleScroll, true)
  },
  methods: {
      handleScroll() {
       let top = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
       this.scrollTopNum = top
      },
      backTop() {
        $('html, body').stop().animate({ scrollTop: 0 }, 500);
      }
  },
  watch: {
    scrollTopNum : function () {
      if (this.scrollTopNum > 50) {
        this.tabshow = true
      } else {
        this.tabshow = false
      }
    }
  }
})
</script>

<style lang="less" scoped>

#app {
  font-family: 'Montserrat', "Noto Sans Japanese", sans-serif !important;
  color: #000;
}



.navbar-default .navbar-nav>li>a {
  font-weight: bold;
  color: #000;  
}

nav {
    padding: 30px;
    background-color: #ffffff;

  .navbar-header {
    @media (min-width:768px) {
    strong {
      font-size: 24px;
      color: #6f78b3;
      text-shadow: 5px 5px 5px #6f78b3;   
      }
    }

    @media (max-width: 768px) {
      strong {
        font-size: 24px;
        color: #6f78b3;
        text-shadow: 5px 5px 5px #6f78b3;   
        }
      }
  }
}

.navbar-nav>li>a{
    font-size: 16px;
    &:hover {
     text-decoration: underline ;
    }
   }

.footer-container {
    height:100px;
    border-top: 1px solid  #e7e7e7;
    footer{
        &>.pagetop{
          position: fixed;
          right: 45px;
          bottom: 45px;
          background: #ffffff;
          font-size: 0;
          line-height: 0;
      }
      &>.copyright {
        background-color: #ffffff;
        text-align: center; 
        line-height: 100px;
        @media (max-width: 768px) {
            small {
              font-size: 80%;
            }
        }
      }
    }
}
</style>
