import { render, staticRenderFns } from "./ContactView.vue?vue&type=template&id=1f3d3fe6&scoped=true&"
var script = {}
import style0 from "./ContactView.vue?vue&type=style&index=0&id=1f3d3fe6&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f3d3fe6",
  null
  
)

export default component.exports