import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'
import BusinessView from '../views/BusinessView.vue'
import CorporateView from '../views/CorporateView.vue'
import RecruitView from '../views/RecruitView.vue'
import ContactView from '../views/ContactView.vue'

Vue.use(VueRouter)

const routes = [{
        path: '/home',
        name: 'home',
        component: HomeView
    },
    { path: '/', redirect: '/home' },
    {
        path: '/business',
        name: 'business',
        component: BusinessView
    },
    {
        path: '/corporate',
        name: 'corporate',
        component: CorporateView
    },
    {
        path: '/recruit',
        name: 'recruit',
        component: RecruitView
    },
    {
        path: '/contact',
        name: 'contact',
        component: ContactView
    },
    {
        path: '*',
        redirect: '/home',
        name: 'notFound',
        hidden: true
    }
]

const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
            return { selector: to.hash }
        } else if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 }
        }
    }
})

export default router