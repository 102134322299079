<template>
  <div class="home"> 
    <div class="fullSlide">
            <div class="col-md-12">
                <!--轮播图-->
                <div id="carousel-example-generic" class="carousel slide main-content" data-ride="carousel">
                    <ol class="carousel-indicators">
                        <li data-target="#carousel-example-generic" data-slide-to="0" class="active"></li>
                        <li data-target="#carousel-example-generic" data-slide-to="1" class=""></li>
                        <li data-target="#carousel-example-generic" data-slide-to="2" class=""></li>
                        <li data-target="#carousel-example-generic" data-slide-to="3" class=""></li>
                        <li data-target="#carousel-example-generic" data-slide-to="4" class=""></li>

                    </ol>
                    <div class="carousel-inner" role="listbox">
                        <div class="item  active">
                          <img class="carousel-img1"  data-holder-rendered="true">
                            <div class="carousel-caption">
                           </div>    
                        </div>
                        <div class="item ">
                            <img class="carousel-img2"  data-holder-rendered="true">
                            <div class="carousel-caption">
                               
                            </div>
                        </div>
                        <div class="item ">
                            <img class="carousel-img3" data-holder-rendered="true">
                            <div class="carousel-caption">
                               
                            </div>
                        </div>
                        <div class="item ">
                            <img class="carousel-img4"  data-holder-rendered="true">
                            <div class="carousel-caption">
                               
                            </div>
                        </div>
                        <div class="item ">
                            <img class="carousel-img5" data-holder-rendered="true">
                            <div class="carousel-caption">
                               
                            </div>
                        </div>
                    </div>
                    <a class="left carousel-control" href="#carousel-example-generic" role="button" data-slide="prev">
                        <span class="glyphicon glyphicon-chevron-left" aria-hidden="true"></span>
                        <span class="sr-only">Previous</span>
                    </a>
                    <a class="right carousel-control" href="#carousel-example-generic" role="button" data-slide="next">
                        <span class="glyphicon glyphicon-chevron-right" aria-hidden="true"></span>
                        <span class="sr-only">Next</span>
                    </a>
                </div>
            </div>
        </div>
        <div class="countents">
          <div class="container">
              <div class="primary">
                <div>
                    <h3 class="page-header">ステップバイステップ、ITソリューションズを介して社会へ貢献できるように</h3>
                    <div class="row">
                      <div class= "col-xs-6">
                        <p>株式会社SBSは、世の中の新しい動きを見据えながら、顧客の多彩な需要に応えるように常に初心に返る心構えを持ち、
                          堅実に専門性を最大限に活用します。あらゆる顧客の抱える悩みを解決できるように、
                          既存ノウハウを基に新しいテクノロジーを取り込んでチャレンジをし続けます。</p>
                      </div>
                      <div class= "col-xs-6">
                        <div class=" img-responsive ">
                          <img  class="img-responsive" src="../assets/image/20221206170442135.jpg"/>
                        </div>
                      </div>
                    </div>
                </div>
                <div>
                <h3 class="page-header">技術開発サービス</h3>
                  <div class="row service">
                    <div class="col-sm-6 col-md-4 card">
                        <div class="img-responsive">
                          <router-link to="/business?#managementSystemDevelopment">
                            <img src="../assets/image/img_service1.jpg" alt="...">
                            <h5>管理システム開発</h5>
                        </router-link>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 card">
                        <div class=" img-responsive">
                          <router-link to="/business?#webSystemDevelopment">
                            <img src="../assets/image/img_service2.jpg" alt="...">
                            <h5>WEBシステム開発</h5>
                          </router-link>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 card">
                        <div class=" img-responsive">
                          <router-link to="/business?#crmSystemDevelopment">
                            <img src="../assets/image/img_service3.jpg" alt="...">
                            <h5>CRMシステム開発</h5>
                        </router-link >
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 card">
                        <div class=" img-responsive">
                            <router-link to="/business?#mbeddedSystemDevelopment">
                              <img src="../assets/image/img_service4.jpg" alt="...">
                              <h5>IOS&Androidアプリ開発</h5>
                          </router-link>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 card">
                        <div class=" img-responsive">
                          <router-link to="/business?#consulting">
                            <img src="../assets/image/img_service5.jpg" alt="...">
                            <h5>コンサルティング</h5>
                          </router-link>
                        </div>
                    </div>
                    <div class="col-sm-6 col-md-4 card">
                        <div class=" img-responsive">
                          <router-link to="/business?#dataMigration">
                            <img src="../assets/image/img_service6.jpg" alt="...">
                            <h5>データ移行</h5>
                          </router-link>
                        </div>
                    </div>
                  </div>
                </div>
                <div>
                   <h3 class="page-header">ニュース・お知らせ</h3>
                   <div v-for="item in newslist" :key="item.id">
                      <dl class="dl-horizontal">
                        <dt >{{ item.datetime}}</dt>
                        <dd>
                          <span class="badge category ">{{ item.type}}</span> 
                          <span data-toggle="tooltip" data-placement="right" title="詳細の表示">
                            <a href="#" @click="myclick(item)"  data-toggle="modal" data-target=".bs-example-modal-lg">{{ item.title}}</a>
                          </span>
                        </dd>
                      </dl>
                  </div>
                </div>
             </div>
          </div>
       </div>

        <!-- Modal -->
        <div class="modal fade bs-example-modal-lg" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel">
          <div class="modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                <h4 class="modal-title" id="myModalLabel"> 詳細の表示</h4>
              </div>
              <div class="modal-body">
                <h4>タイトル：</h4>
                <p>{{title}}</p>
                <hr></hr>
                <h4>内容：</h4>
                <p>{{content}}</p>
              </div>
            </div>
          </div>
        </div>
  </div>
</template>

<script>    

import axios from "axios"
import * as d3 from "d3"

export default {
  name: 'HomeView',
  components: {
   
  },
  data() {
    return {
      newslist:[],
      title:"",
      content:""
    }
  },
  created() {
       axios.get("/static/file/news.csv").then(res=> {
        console.log(res.data)
        this.newslist = d3.csvParse(res.data);
        console.log(this.newslist)
       })
  },
  methods:{
    myclick(news){

      this.title = news.title
      this.content = news.content

    }
  }
}


</script>

<style lang="less" scoped>

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
  font-weight: bold;  
  color: inherit;
} 

.fullSlide {
    width: 100%;
    height: 410px;
}

.carousel-img1 {
  background: url("../assets/image/slider_img1.jpg") center 0px no-repeat rgb(255, 255, 255);
}

.carousel-img2 {
  background: url("../assets/image/slider_img2.jpg") center 0px no-repeat rgb(255, 255, 255);
}

.carousel-img3 {
  background: url("../assets/image/slider_img3.jpg") center 0px no-repeat rgb(255, 255, 255);
}

.carousel-img4 {
  background: url("../assets/image/slider_img4.jpg") center 0px no-repeat rgb(255, 255, 255);
}

.carousel-img5 {
  background: url("../assets/image/slider_img5.jpg") center 0px no-repeat rgb(255, 255, 255);
}

.col-md-12 {
  padding: 0;
}
 
.carousel,
.carousel img {
    width: 100%;
    height: 410px !important;
}

@media screen and (max-width: 768px){
  .fullSlide {
    width: 100%;
    height: 200px;
   }
    .carousel,
    .carousel img {
        width: 100%;
        height: 200px !important;
    }
  }

.dl-horizontal {
  .category {
  margin-right: 10px;
  background-color: #e4a200;
  
 }
}

.countents {
 margin-top: 20px;

}

.service {
  text-align: center;
}

.card .img-responsive img{
   height: 200px;
   width: 100%;
    &:hover {
      box-shadow: 0 16px 32px 0 rgba(48, 55, 66, 0.15);
      transform: translate(0, -5px);
      transition-delay: 0s !important;
  }
}
</style>